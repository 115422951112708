import axios from 'axios';
import { combineReducers } from 'redux';
import { validate, v4 as uuidv4 } from 'uuid';
import {
  ADD_NEXT_FORM, DELETE_FORM, SET_GENERAL_COMMENT, SET_ORDER_NUMBER, SET_ZAMOWIENIE, UPDATE_FORM, SET_BLOCKED, SET_TEST,
} from './actions';
// import json from './Pages/ProductionMaterials/tmpJson.json';

const appInitialState = {
  forms: [],
  orderNumber: null,
  productionMaterials: null,
  // productionMaterials: json,
  signatures: null,
  authenticated: false,
  zamowienie: null,
};

const setZamowienie = (state, products) => ({ ...state, zamowienie: products });
const setBlocked = (state, blocked) => ({ ...state, blocked });
const setTest = (state, test) => ({ ...state, test });

const insertForm = (state, form) => {
  const formsCopier = [...state.forms];
  const forms = formsCopier.map((f) => ({ ...f, show: false }));
  forms.push(form);

  return { ...state, forms };
};

const updateForm = (state, updatedForm) => {
  const newForms = state.forms.map((form) => (form.id === updatedForm.id ? updatedForm : form));

  return { ...state, forms: newForms, productionMaterials: null };
};

const deleteForm = (state, id) => {
  const formIndexToDelete = state.forms.findIndex((f) => f.id === id);
  if (formIndexToDelete < 0) {
    throw new Error(`id ${id} not found`);
  }

  const forms = [...state.forms];
  forms.splice(formIndexToDelete, 1);

  return { ...state, forms };
};

const FETCH_PRODUCTION_MATERIALS = 'FETCH_PRODUCTION_MATERIALS';

// readOrderId tries to read order id form given url
// e.g. for https://www.test.api.my.konsport.com/api/orders/f0784f32-16a3-4f3f-8b3a-e33b63304e42/production-materials
// it should return f0784f32-16a3-4f3f-8b3a-e33b63304e42
// In case of issue Will log error and return undefined
const readOrderId = (url) => {
  // eslint-disable-next-line no-console
  console.log(url);
  if (!url) {
    // eslint-disable-next-line no-console
    console.error('undefined url');
    return undefined;
  }
  // eslint-disable-next-line
  const orderId = url.match(/(?!\/)\d+(?=\?)/);
  const ok = validate(orderId);
  if (!ok) {
    // eslint-disable-next-line no-console
    console.error('wrong id format');
    return undefined;
  }
  return orderId;
};

// recalculateProductionMaterials dispatches an async request and do not care about the result
const recalculateProductionMaterials = (orderId) => {
  // eslint-disable-next-line no-console
  console.debug('production materials not found, dispatching request to recalculate them...');
  axios.post(`/zamowienia/${orderId}/recalculate-production-materials`);
};

export const fetchProductionMaterialsTwice = (orderIds, finallyCb) => () => {
  Promise.all(orderIds.map((id) => {
    const url = `/orders/${id}/production-materials`;
    return axios
      .get(url);
  })).then((multiData) => {
    const flatOrders = multiData.map((d) => d.data).flat(2);
    flatOrders.forEach((o) => {
      // eslint-disable-next-line no-console
      const noMaterials = o.products.some((p) => !p.productionMaterials);
      if (noMaterials) {
        recalculateProductionMaterials(o.id);
      }
    });
  }).catch((e) => {
    if (e?.response?.status === 404) {
      const oId = readOrderId(e?.config?.url);
      if (!oId) return;
      recalculateProductionMaterials(oId);
      return;
    }
    throw e;
  }).finally(finallyCb);
};

// TODO use useQuery
export const fetchProductionMaterialsMulti = (orderIds, finallyCb) => (dispatch) => {
  Promise.all(orderIds.map((id) => {
    const url = `/orders/${id}/production-materials`;

    // Function to fetch production materials with retry logic
    // eslint-disable-next-line arrow-body-style
    const fetchWithRetry = async () => {
      return axios.get(url).then((response) => {
        const order = response.data;
        const noMaterials = order.products.some((p) => !p.productionMaterials);

        if (noMaterials) {
          recalculateProductionMaterials(order.id);

          // Wait 5 seconds and retry
          return new Promise((resolve) => {
            setTimeout(() => {
              axios.get(url).then(resolve).catch(resolve);
            }, 10000);
          });
        }
        return response;
      });
    };
    return fetchWithRetry();
  })).then((multiData) => {
    const flatOrders = multiData.map((d) => d.data).flat(2);
    dispatch({ type: FETCH_PRODUCTION_MATERIALS, productionMaterials: flatOrders });
  }).catch((e) => {
    if (e?.response?.status === 404) {
      const oId = readOrderId(e?.config?.url);
      if (!oId) return;
      recalculateProductionMaterials(oId);
      return;
    }
    throw e;
  }).finally(finallyCb);
};

export const fetchProductionMaterialsCards = (orderIds, finallyCb) => (dispatch) => {
  Promise.all(orderIds.map((id) => {
    const url = `/production-materials-manually-calculated/${id}`;
    return axios
      .get(url);
  })).then((multiData) => {
    const flatOrders = multiData.map((d) => d.data).flat(2);
    const flatOrdersWithUUIDs = flatOrders.map((order) => ({
      ...order,
      products: order.products.map((product) => ({
        ...product,
        id: uuidv4(),
      })),
    }));
    dispatch({ type: FETCH_PRODUCTION_MATERIALS, productionMaterials: flatOrdersWithUUIDs });
  }).catch((e) => {
    if (e?.response?.status === 404) {
      const oId = readOrderId(e?.config?.url);
      if (!oId) return;
      return;
    }
    throw e;
  }).finally(finallyCb);
};

const app = (state = appInitialState, action) => {
  switch (action.type) {
    case ADD_NEXT_FORM:
      return insertForm(state, action.form);
    case UPDATE_FORM:
      return updateForm(state, action.form);
    case SET_ORDER_NUMBER:
      return { ...state, orderNumber: action.orderNumber };
    case SET_GENERAL_COMMENT:
      return { ...state, generalComment: action.generalComment };
    case DELETE_FORM:
      return deleteForm(state, action.id);
    case FETCH_PRODUCTION_MATERIALS:
      return {
        ...state,
        productionMaterials: action.productionMaterials,
      };
    case 'SET_SIGNATURES':
      return {
        ...state,
        signatures: action.signatures,
      };
    case 'SIGN_IN':
      return { ...state, authenticated: true, authError: null };
    case 'SIGN_OUT':
      return { ...state, authenticated: false, authError: null };
    case 'SIGN_OUT_ON_ERROR':
      return { ...state, authenticated: false, authError: action.error };
    case SET_ZAMOWIENIE:
      return setZamowienie(state, action.products);
    case SET_BLOCKED:
      return setBlocked(state, action.blocked);
    case SET_TEST:
      return setTest(state, action.test);
    default:
      return state;
  }
};

const appReducer = combineReducers({
  app,
});

export default appReducer;
