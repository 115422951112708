import React from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { signOut } from 'auth';
import { setOrderNumber, DELETE_FORM } from '../../actions';

// eslint-disable-next-line arrow-body-style
const Header = () => {
  const { app: appState } = useSelector((s) => s);
  const { forms } = appState;
  const dispatch = useDispatch();

  const cleanUp = () => {
    forms.map((form) => (
      dispatch({ type: DELETE_FORM, id: form.id })
    ));
    dispatch(setOrderNumber(null));
  };

  return (
    <Container>
      <Link to="/" onClick={() => { cleanUp(); }}>
        <Button>Strona głowna</Button>
      </Link>
      <Button onClick={() => signOut(dispatch)}>
        Wyloguj
      </Button>
    </Container>
  );
};

const Container = styled.div`
  background: #2d3234;
  display: flex;
  justify-content: flex-end;
`;

const Button = styled.button`
   border-radius: 22px;
   padding: 0.25rem 2.5rem;
   margin: 20px 10px;
`;

export default Header;
